import React from "react";
import "./Prijzen.css";
import "./Cards.css";
import CardItem from "./CardItem";

function Prijzen() {
  return (
    <div className="prijzen">
      <video src="/videos/prijzen-video.mp4" autoPlay loop muted />
      <h1>Prijzen</h1>
      <p>
        Voor prijzen of andere vragen kunt u me bereiken op de volgende
        manieren.
      </p>
      <p>
        Bel of whatsapp naar{" "}
        <a className="a" href="tel:0631205538">
          0631205538
        </a>
      </p>
      <p>
        Of stuur een mail naar{" "}
        <a className="a" href="mailto:info@jaymian-lee.nl">
          info@jaymian-lee.nl
        </a>
      </p>
      <div className="prijzenCards">
        <div className="cards__container">
          <div className="cards__wrapper">
            <ul className="cards__items">
              <CardItem
                src="images/xiaomi.jpg"
                prijzen="Kapotte Xiaomi? Geen probleem!"
                label="Xiaomi"
              />
              <CardItem
                src="images/kapotte_samsung.jpg"
                prijzen="Kapotte Samsung? Geen probleem!"
                label="Samsung"
              />
              <CardItem
                src="images/fili-santillan-vzo-g6y3Xlw-unsplash.jpg"
                prijzen="Kapotte iPhone Xs? Geen probleem!"
                label="iPhone"
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prijzen;
