import React from "react";
import "./Informatie.css";

function Informatie() {
  return (
    <div className="informatie">
      <div className="row">
        <div className="column">
          <img className="img" src="images/foto.jpg" />
        </div>
        <div className="column">
          <p className="informatie-text">
            Mijn naam is Jaymian-Lee. Student aan het Vista College te Heerlen.
            Ik heb een passie voor electronica en de software die erachter zit.
            Naastbij mijn studie repareer ik computers, laptops, telefoons en
            andere electronica. Ook maak ik websites, web apps en telefoon apps!
            Heb jij nu hulp nodig omdat je telefoon of tablet kapot is of heb je
            nog andere vragen? Bel of mail me gerust met de knoppen hieronder!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Informatie;
