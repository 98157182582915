import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import Prijzen from '../Prijzen';
import Footer from '../Footer';
export default function Products() {
  return (
    <>
      <Prijzen />
      <Footer />
    </>
  );
}
