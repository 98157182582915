import React from "react";
import "../App.css";
import { Button } from "./Button";
import { Button1 } from "./Button1";
import { Button2 } from "./Button2";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container">
      <video src="/videos/repair-video.mp4" autoPlay loop muted />
      <h1>Lee-Solutions</h1>
      <p class="hero-header">Voor al uw smartphone en tablet reparaties!</p>
      <p className="locationText">Heiveldstraat 35, 6466AG Kerkrade</p>
      <div className="hero-btns">
        <Button1
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          Prijzen
        </Button1>
        <Button2
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          Informatie
        </Button2>
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          Maak een afspraak!
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
