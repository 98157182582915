import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Voor al uw smartphone en tablet reparaties!
        </p>
        <p className='footer-subscription-text'>
          Lee-Solutions
        </p>
        <p className='footer-subscription-heading'>
          Heiveldstraat 35, 6466AG Kerkrade
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Uw Email'
            />
            <Button buttonStyle='btn--outline'>Mail</Button>
          </form>
        </div>
      </section>
      <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Contact</h2>
            <Link onClick={()=> window.open("https://www.google.com/maps/place/Lee-Solutions/@50.8645886,6.0398449,17z/data=!3m1!4b1!4m5!3m4!1s0x47c0a396ce607785:0x4136305a3cad08e4!8m2!3d50.8645886!4d6.0420336")}>Google maps</Link>      
            <Link onClick={()=> window.open("mailto:info@jaymian-lee.nl")}>Mail</Link>      
            <Link onClick={()=> window.open("tel:+31631205538")}>Telefoonnr</Link>      
            <Link onClick={()=> window.open("tel:+31631205538")}>+31631205538</Link>    
          </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Navigatie</h2>
            <Link to='/'>Homepagina</Link>
            <Link to='services'>Informatie</Link>
            <Link to='products'>Prijzen</Link>
            <Link to='/'>Contact</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link onClick={()=> window.open("https://www.instagram.com/lee.solutions/")}>Instagram</Link>
            <Link onClick={()=> window.open("https://www.facebook.com/Lee-Solutions-103173682371615")}>Facebook</Link>
            <Link onClick={()=> window.open("https://www.linkedin.com/in/jaymian-lee-reinartz-9b02941b0/")}>LinkedIn</Link>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              <img />
            </Link>
          </div>
          <small class='website-rights'>Lee-Solutions © 2022</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              onClick={()=> window.open("https://www.facebook.com/Lee-Solutions-103173682371615")}
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              onClick={()=> window.open("https://www.instagram.com/lee.solutions/")}
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              onClick={()=> window.open("https://www.linkedin.com/in/jaymian-lee-reinartz-9b02941b0/")}
              target='_blank'
              aria-label='Youtube'
            >
                <i class='fab fa-linkedin' />
             
               </Link>
              <Link
              class='social-icon-link linkedin'
              onClick={()=> window.open("https://www.linkedin.com/in/jaymian-lee-reinartz-9b02941b0/")}
              target='_blank'
              aria-label='Youtube'
            >
             <i class='fab fa-youtube' />
            </Link>
              
           
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
