import React from 'react';
import '../../App.css';
import Informatie from '../Informatie';
import Footer from '../Footer';
export default function Products() {
  return (
    <>
      <Informatie />
      <Footer />
    </>
  );
}
